import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMultiStyleConfig } from '@chakra-ui/react';
import { Box } from '../Box';
import { Button } from '../Button';
import { ProBadgeIcon } from '../Icon';
import { Text } from '../Text';
import { HatchLogoRotatedIcon } from './icons';
// TODO: Figma says text small but fontSize is different
export const InformationalUpgradeCTA = ({ heading, body, variant, isProMember = false, onClick, }) => {
    const styles = useMultiStyleConfig('InformationalUpgradeCTA', { variant });
    return (_jsxs(Button, { __css: styles.box, onClick: onClick, children: [_jsx(Box, { __css: styles.logoWrap, children: _jsx(HatchLogoRotatedIcon, { fill: styles.logo?.fill }) }), _jsxs(Box, { children: [_jsx(Text, { size: 'sm', fontWeight: 'bold', fontSize: '14px', children: heading }), _jsx(Text, { as: 'div', size: 'xs', children: body })] }), _jsx(Box, { ml: 3, children: _jsx(ProBadgeIcon, { h: 5, w: 5, fill: isProMember ? 'gray.500' : 'purple.700' }) })] }));
};
